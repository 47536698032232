
function freeze(obj) {
  return Object.freeze ? Object.freeze(obj) : obj;
}

export const deviceType = freeze({
  bat1: 'bat1Scale',
  bat2: 'bat2Scale',
  bat2Cable: 'bat2CableScale',
  bat2Gsm: 'bat2GsmScale',
  bat2Lora: 'bat2LoraScale',
  bat2Connect: 'bat2ConnectScale',
  bat2BlackBox: 'bat2BlackBoxScale',
  temperature: 'temperature',
  humidity: 'humidity',
  carbonDioxide: 'carbonDioxide',
  terminal: 'batCollectorTerminal',
  isWeight: type => type === 'bat2CableScale' || type === 'bat2GsmScale' || type === 'bat2Scale' || type === 'bat2LoraScale' || type === 'bat2ConnectScale' || type === 'bat2BlackBoxScale',
});

export const deviceTypeName = freeze({
  [deviceType.bat1]: 'BAT1',
  [deviceType.bat2]: 'BAT2',
  [deviceType.bat2Cable]: 'BAT2 Cable',
  [deviceType.bat2Gsm]: 'BAT2 GSM',
  [deviceType.bat2Lora]: 'BAT2 Air',
  [deviceType.bat2Connect]: 'BAT2 Connect',
  [deviceType.bat2BlackBox]: 'BAT2 BlackBox',
  [deviceType.temperature]: 'Temperature',
  [deviceType.humidity]: 'Humidity',
  [deviceType.carbonDioxide]: 'Carb. Diox.',
  [deviceType.terminal]: 'Terminal',
});

export const unitType = freeze({
  g: 'g',
  kg: 'kg',
  lb: 'lb',
  c: '°C',
  ppm: 'ppm',
  percent: '%',
  pieces: 'pcs',
});

export const unitTypeName = freeze({
  [unitType.g]: 'grams',
  [unitType.kg]: 'kilograms',
  [unitType.lb]: 'pounds',
});

export const dateType = freeze({
  day: 'day',
  week: 'week',
  getPlural: type => `${type}s`,
});

export const sexType = {
  irrelevant: 'irrelevant',
  undefined: 'undefined',
  male: 'male',
  female: 'female',
  mixed: 'mixed',
};

export const roleType = freeze({
  undefined: 'undefined',
  noAccess: 'noAccess',
  user: 'user',
  admin: 'admin',
  companyAdmin: 'companyAdmin',
  veitOperator: 'veitOperator',
  isAdmin: type => type === 'veitOperator',
  canUpdate: role => ['companyAdmin', 'veitOperator', 'admin'].indexOf(role) !== -1,
});

export const helpActionType = freeze({
  none: 'none',
  requestGateway: 'requestGateway',
});

export const helpActionTypeName = freeze({
  [helpActionType.none]: 'None',
  [helpActionType.requestGateway]: 'Request gateway for country',
});

export const localeType = freeze({
  english: 'en',
  german: 'de',
  french: 'fr',
  japanese: 'jp',
  isValid: locale => locale === 'en' || locale === 'de' || locale === 'fr' || locale === 'jp',
});

export const weightUnitType = freeze({
  grams: 'g',
  pounds: 'lb',
  isValid: weightUnit => weightUnit === 'g' || weightUnit === 'lb',
});

const auth = {
  get: 'get',
  create: 'post',
  update: 'put',
  delete: 'delete',
};

const hasAuth = (item, authType) => {
  return item != null && item.links != null && item.links[authType] != null;
};

export const authType = {
  ...auth,
  hasAuth,
  canCreate: item => hasAuth(item, auth.create),
  canUpdate: item => hasAuth(item, auth.update),
  canDelete: item => hasAuth(item, auth.delete),
};

export const statsType = {
  automatic: 'automatic',
  manual: 'manual',
};

export const sortingType = {
  none: 'none',
  sex: 'sex',
  lightHeavy: 'lightheavy',
  lightOkHeavy: 'lightokheavy',
  categories: 'categories',
  fleshingScore: 'fleshingscore',
};

export const categoryType = {
  none: 'none',
  male: 'male',
  female: 'female',
  undefined: 'undefined',
  light: 'light',
  ok: 'ok',
  heavy: 'heavy',
};
