import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Table } from '@veit/veit-web-controls';
import { IMDialMeter } from '@veit/veit-web-controls/dist/icons';

import { openDialogAddDevice, openDialogEditDevice } from '../../store/ModalDevice.actions';

import Page from '../../components/Page';
import QuerySort from '../../components/QuerySort';
import { AuthAddButton } from '../../components/AuthButton';
import bem from '../../utils/bem';
import pagination from '../../utils/pagination';
import goToConnect from '../../utils/goToConnect';
import { deviceOrder } from '../../model/order';
import intl from '../../setup/RIntl';

import fetchData from './fetchData';
import TableRow from './TableRow';
import DeviceFilter from './DeviceFilter';

const messages = defineMessages({
  myScales: { id: 'devices.my-scales', defaultMessage: 'My Scales' },
});

class DeviceList extends Component {
  state = { isFetching: true }

  componentDidMount() {
    this.getData(true).then(() => this.setState({ isFetching: false }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.getData(false);
    }
  }

  getData = (fetchMetadata) => {
    return this.props.fetchData(fetchMetadata, pagination(this.props.location.search));
  }

  addDevice = () => {
    this.props.openDialogAddDevice().then(this.resolveDialog);
  }

  resolveDialog = (result) => {
    if (result == null) return;
    const last = this.props.device.items.length === 1;
    if (result.delete && last) {
      this.props.goToPage(0);
    } else {
      this.getData(false);
    }
  }

  actions = () => {
    return (
      <React.Fragment>
        <AuthAddButton canCreate={this.props.device} onClick={this.addDevice}>
          <FormattedMessage id="devices.add-scale" defaultMessage="Add Scale" />
        </AuthAddButton>
      </React.Fragment>
    );
  }

  editDeviceHandler = (e, device) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.openDialogEditDevice(device).then(this.resolveDialog);
  }

  render() {
    const bm = bem.view('device-list');
    const devices = this.props.device.items;
    return (
      <Page
        className={bm.b()}
        title={intl.t(messages.myScales)}
        actions={this.actions()}
        isFetching={this.props.fetch.isFetching || this.state.isFetching}
        emptyPage={{
          isEmpty: devices.length === 0,
          icon: IMDialMeter,
          action: (
            <AuthAddButton canCreate={this.props.device} onClick={this.addDevice}>
              <FormattedMessage id="common.add-scale" defaultMessage="Add Scale" />
            </AuthAddButton>
          ),
        }}
        paginate={{ onChange: this.props.goToPage, ...this.props.device }}
        filter={{
          search: true,
          body: <DeviceFilter />,
        }}
      >
        <Table type="info">
          <thead>
            <tr>
              <th>
                <QuerySort>
                  <FormattedMessage id="common.device" defaultMessage="Device" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={deviceOrder.type}>
                  <FormattedMessage id="common.type" defaultMessage="Type" />
                </QuerySort>
              </th>
              {/* <th>Connected</th> */}
              <th className="text-center">
                <FormattedMessage id="common.status" defaultMessage="Status" />
              </th>
              <th>
                <QuerySort orderby={deviceOrder.date}>
                  <FormattedMessage id="common.last" defaultMessage="Last update" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={deviceOrder.age}>
                  <FormattedMessage id="common.age" defaultMessage="Age" />
                </QuerySort>
              </th>
              <th className="text-center">
                <FormattedMessage id="common.sex" defaultMessage="Sex" />
              </th>
              <th>
                <QuerySort orderby={deviceOrder.value}>
                  <FormattedMessage id="common.last-value" defaultMessage="Last value" />
                </QuerySort>
              </th>
              <th>
                <QuerySort orderby={deviceOrder.location}>
                  <FormattedMessage id="common.location" defaultMessage="Location" />
                </QuerySort>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {devices.map(d => (
              <TableRow
                key={d.id}
                device={d}
                editDeviceHandler={this.editDeviceHandler}
                dateFormat={this.props.dateFormat}
                weightUnit={this.props.weightUnit}
              />
            ))}
          </tbody>
        </Table>
      </Page>
    );
  }
}

DeviceList.propTypes = {
  fetchData: PropTypes.func.isRequired,
  openDialogAddDevice: PropTypes.func.isRequired,
  openDialogEditDevice: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  device: PropTypes.shape({
    items: PropTypes.array,
    links: PropTypes.object,
  }),
  fetch: PropTypes.shape({
    isFetching: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  weightUnit: PropTypes.string,
  dateFormat: PropTypes.string,
};

DeviceList.defaultProps = {
  device: null,
  fetch: {},
  weightUnit: null,
  dateFormat: null,
};

const mapStateToProps = (state) => {
  return {
    device: state.device,
    fetch: state.fetch,
    locale: state.auth.locale,
    weightUnit: state.auth.weightUnit,
    dateFormat: state.auth.dateFormat,
  };
};

const mapDispatchToProps = {
  fetchData,
  openDialogAddDevice,
  openDialogEditDevice,
};

export default goToConnect('/scales')(DeviceList, mapStateToProps, mapDispatchToProps);
