import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { loginRedirect } from '../../store/Auth.actions';
import { openDialogRegisterUser } from '../../store/ModalUser.actions';
import bem from '../../utils/bem';

// LanguageSelector needs to be as it is - otherwise change lang in home screen
// (when you are log out) doesn't work.
import LanguageSelector from '../../components/LanguageSelector';
import QueryRedirect from '../../components/QueryRedirect';

import { ReactComponent as VeitLogo } from '../../content/svg/VEIT_logo_150.svg';

import Section1 from './Section1';
import Section3 from './Section3';
import Section4 from './Section4';

const bm = bem.view('home');
const header = bem.view('home-header');

class Home extends Component {
  render() {
    if (this.props.isAuthenticated) return <QueryRedirect to="/flocks" />;
    return (
      <React.Fragment>
        <div className={header.b('sticky')}>
          <div className="nav-left">
            <a href="https://www.veit.cz/" target="_blank" rel="noreferrer noopener">
              <VeitLogo height="62px" />
            </a>
          </div>
          <div className="nav-right">
            <div role="presentation">
              <LanguageSelector />
            </div>
            {/* <div
              className="link"
              role="presentation"
              onClick={() => this.props.openDialogRegisterUser()}
            >
              <FormattedMessage id="common.sign-up" defaultMessage="Sign up" />
            </div> */}
            <div className="link" role="presentation" onClick={() => this.props.loginRedirect()}>
              <FormattedMessage id="common.log-in" defaultMessage="Log In" />
            </div>
          </div>
        </div>
        <div className={bm.b()}>
          <Section1 />
          <Section3 />
          <Section4 />
        </div>
      </React.Fragment>

    );
  }
}

Home.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  openDialogRegisterUser: PropTypes.func.isRequired,
  loginRedirect: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  openDialogRegisterUser,
  loginRedirect,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);
