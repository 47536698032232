import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {
  UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, Label, Button,
} from '@veit/veit-web-controls';
// import IMErrorMark from '@veit/veit-web-controls/dist/icons/IMErrorMark';

import fetchData from './fetchData';
import { openDialogAddDevice } from '../../store/ModalDevice.actions';
import { openDialogEditLocation } from '../../store/ModalLocation.actions';
import { openDialogExportStats } from '../../store/ModalExportStats.actions';
import { openDialogHistogram } from '../../store/ModalHistogram.actions';
import { openDialogAddFlock, openDialogCloseFlock } from '../../store/ModalFlock.actions';
import { goTo } from '../../store/Router.actions';
import { getDevicesByLocation } from '../../store/Device.actions';


import Subtitle from './Subtitle';
import TabsBar, { tabType } from '../_tabs/TabsBar';
import WeighingTab from '../_tabs/WeighingTab';
import FlocksTab from '../_tabs/FlocksTab';
import SourcesTab from '../_tabs/SourcesTab';
import GraphsTab from '../_tabs/GraphsTab';
import bem from '../../utils/bem';
import { groupByDay } from '../../utils/stats';
import Page from '../../components/Page';
import AuthButton, { AuthAddButton } from '../../components/AuthButton';
import dayjs from 'dayjs';

const statsAgeDefault = 30;

const statsAgeOptions = [
  statsAgeDefault,
  60,
  90,
];

class Overview extends Component {
  state = {
    statsMaxAge: null,
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = (statsMaxAge) => {
    this.props.fetchData(this.props.params.id, {
      from: this.getFromFilter(statsMaxAge),
    });
  }

  resolveDialog = (update) => {
    if (update != null) {
      this.fetchData(this.state.statsMaxAge);
    }
  }

  editLocation = () => {
    this.props.openDialogEditLocation(this.props.location.item).then((result) => {
      if (result != null && result.delete) {
        this.props.goTo('/locations');
      }
    });
  }

  closeFlock = (flock) => {
    this.props.openDialogCloseFlock(flock).then(this.resolveDialog);
  };

  createFlock = () => {
    this.props.openDialogAddFlock({ locationId: this.props.location.item.id })
      .then(this.resolveDialog);
  }

  exportData = () => {
    const { activeFlock, id } = this.props.location.item;
    this.props.openDialogExportStats({
      flock: activeFlock || {},
      locationId: id,
      weightUnit: this.props.weightUnit,
      dateFormat: this.props.dateFormat,
    });
  }

  showHistogram = (stats, stat) => {
    this.props.openDialogHistogram(stat, stats);
  }

  applyFilter = (stats, ascending) => {
    if (stats == null) return [];
    return stats.sort((a, b) => (ascending ? a.dateTime - b.dateTime : b.dateTime - a.dateTime));
  }

  hasHistogram = (histogram) => {
    if (histogram == null || histogram.steps == null) return false;
    return Object.keys(histogram.steps).length > 0;
  }

  setStatsMaxAge = (days) => {
    if (days === this.state.statsMaxAge) return;
    this.setState({ statsMaxAge: days });
    this.fetchData(days);
  }

  getDevices = () => {
    const { statsMaxAge } = this.state;
    return this.props.getDevicesByLocation(this.props.params.id, {
      from: this.getFromFilter(statsMaxAge),
    });
  }

  getFromFilter = (statsMaxAge) => {
    return statsMaxAge ? dayjs().add(-statsMaxAge, 'days').format('YYYY-MM-DD') : null;
  }

  addDevice = () => {
    this.props.openDialogAddDevice({ locationId: this.props.params.id }).then((u) => {
      if (u != null) {
        this.props.getDevicesByLocation(this.props.params.id);
      }
    });
  }

  actions() {
    const location = this.props.location.item;
    if (location == null) return null;
    return (
      <React.Fragment>
        {location.activeFlock
          ? (
            <AuthButton canUpdate={location} color="primary" onClick={() => this.closeFlock(location.activeFlock)}>
              <FormattedMessage id="flocks.modal.close-flock" defaultMessage="Close Flock" />
            </AuthButton>
          ) : (
            <AuthButton canUpdate={location} color="primary" onClick={this.createFlock}>
              <FormattedMessage id="flock.create-flock" defaultMessage="Create Flock" />
            </AuthButton>
          )}
        <AuthButton canUpdate={location} color="primary" outline onClick={this.editLocation}>
          <FormattedMessage id="locations.modal.edit-location" defaultMessage="Edit Location" />
        </AuthButton>
        <Button color="primary" outline onClick={this.exportData}>
          <FormattedMessage id="devices.export" defaultMessage="Export" />
        </Button>
      </React.Fragment>
    );
  }

  render() {
    const bm = bem.view('device');
    const location = this.props.location.item || {};
    const data = Array.isArray(this.props.stats)
      ? this.props.stats
      : (this.props.stats || {}).data || [];
    const stats = this.applyFilter(data);
    const dataGroup = groupByDay(stats);
    const unit = this.props.weightUnit;
    const { tab } = this.props.match.params;
    return (
      <Page
        className={bm.b()}
        title={location.deleted ? `[DELETED] ${location.name}` : location.name}
        actions={!location.deleted && this.actions(location.type)}
        isFetching={this.props.fetch.isFetching}
        notFound={location.id !== this.props.params.id}
        subtitle={(<Subtitle flock={location.activeFlock} farm={location.farm} />)}
      >
        <TabsBar
          baseUrl={`/locations/${location.id}`}
          match={this.props.match}
        >
          {tab === tabType.scales && (
            <AuthAddButton canCreate={this.props.location} onClick={this.addDevice}>
              <FormattedMessage id="devices.add-scale" defaultMessage="Add Scale" />
            </AuthAddButton>
          )}
          <UncontrolledDropdown style={{ paddingLeft: '15px' }}>
            <DropdownToggle
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
              color="primary"
              outline
              caret
            >
              {(this.state.statsMaxAge != null || location.activeFlock == null) && (
                <FormattedMessage values={{ count: this.state.statsMaxAge || statsAgeDefault }} id="location.last-n-days" defaultMessage="last {count} days" />
              )}
              {this.state.statsMaxAge == null && location.activeFlock != null && (
                <React.Fragment>
                  <Label><FormattedMessage id="common.flock" defaultMessage="Flock" /></Label>
                  <Label type="info">{location.activeFlock.name}</Label>
                </React.Fragment>
              )}
            </DropdownToggle>
            <DropdownMenu>
              {location.activeFlock && (
                <DropdownItem onClick={() => this.setStatsMaxAge(null)}>
                  <Label><FormattedMessage id="common.flock" defaultMessage="Flock" /></Label>
                  <Label type="info">{location.activeFlock.name}</Label>
                </DropdownItem>
              )}
              {statsAgeOptions.map(days => (
                <DropdownItem key={days} onClick={() => this.setStatsMaxAge(days)}>
                  <Label>
                    <FormattedMessage values={{ count: days }} id="location.last-n-days" defaultMessage="last {count} days" />
                  </Label>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </TabsBar>
        {tab == null && (
          <WeighingTab
            stats={dataGroup}
            locationId={location.id}
            weightUnit={unit}
            dateFormat={this.props.dateFormat}
          />
        )}

        {tab === tabType.graphs && (
          <GraphsTab stats={dataGroup} flock={location.activeFlock} weightUnit={unit} />
        )}

        {tab === tabType.scales && (
          <SourcesTab
            fetchData={this.getDevices}
          />
        )}

        {tab === tabType.flocks && (
          <FlocksTab params={{ location: this.props.params.id }} />
        )}
      </Page>
    );
  }
}

Overview.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
    tab: PropTypes.string,
  }).isRequired,
  fetchData: PropTypes.func.isRequired,
  openDialogAddDevice: PropTypes.func.isRequired,
  openDialogExportStats: PropTypes.func.isRequired,
  openDialogHistogram: PropTypes.func.isRequired,
  openDialogEditLocation: PropTypes.func.isRequired,
  openDialogAddFlock: PropTypes.func.isRequired,
  openDialogCloseFlock: PropTypes.func.isRequired,
  getDevicesByLocation: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  fetch: PropTypes.shape({
    isFetching: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }).isRequired,
  location: PropTypes.shape({
    item: PropTypes.shape({
      id: PropTypes.string,
      locationId: PropTypes.string,
      farmId: PropTypes.string,
      phoneNumber: PropTypes.string,
      activeFlock: PropTypes.object,
    }),
    links: PropTypes.object,
  }),
  stats: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string,
    }),
    PropTypes.array,
  ]),
  match: PropTypes.shape({
    params: PropTypes.object,
    url: PropTypes.string,
  }).isRequired,
  gateways: PropTypes.arrayOf(PropTypes.string).isRequired,
  weightUnit: PropTypes.string,
  dateFormat: PropTypes.string,
};

Overview.defaultProps = {
  location: {},
  stats: null,
  weightUnit: null,
  dateFormat: null,
};

const mapStateToProps = (state, ownProps) => {
  return {
    params: ownProps.match.params,
    match: ownProps.match,
    location: state.location,
    stats: state.stats.item,
    fetch: state.fetch,
    gateways: state.device.gateways,
    weightUnit: state.auth.weightUnit,
    dateFormat: state.auth.dateFormat,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchData,
  openDialogAddDevice,
  openDialogExportStats,
  openDialogHistogram,
  openDialogEditLocation,
  openDialogCloseFlock,
  openDialogAddFlock,
  getDevicesByLocation,
  goTo,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
