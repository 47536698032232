import React from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import {
  Label,
  InputGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonSwitch,
} from '@veit/veit-web-controls';

import SelectBird from '../../components/SelectBird';
import TimeInput, { timeUnits } from '../../components/TimeInput';
import WeightInput, { weightUnits } from '../../components/WeightInput';
import FormLabel from '../../components/FormLabel';

import ModalActions from '../../store/ModalFlock.actions';
import { birdType } from '../../types';

const timeRound = (value, unit) => {
  const days = Number.parseFloat(value);
  return unit !== timeUnits.weeks || Number.isNaN(days) ? value : days - (days % 7);
};

export function hasStep2Error({
  useFinalWeight, targetAge, targetWeight,
}) {
  return {
    targetAge: useFinalWeight === false ? targetAge == null || !validator.isInt(`${targetAge}`, { min: 1 }) : false,
    targetWeight: useFinalWeight === true ? targetWeight == null || !validator.isInt(`${targetWeight}`, { min: 1 }) : false,
  };
}

const Step3 = ({
  modal, updateForm, updateFormObject, updateFormEvent, birds, weightUnit,
}) => {
  const form = modal.data;
  if (form == null) return null;
  const hasErrors = hasStep2Error(form);
  const edit = form.id != null;
  const bird = birds.find(f => f.id === form.birdId);
  if (form.targetInitialCheck === undefined) {
    // ensure check will be made only once at the beginning not on every refresh on the same form
    form.targetInitialCheck = true;
    if (form.targetWeight) {
      form.useFinalWeight = true;
      if (form.targetWeightUnitInitialCheck === undefined) {
        /* ensure check will be made only once at the beginning
        not on every refresh on the same form */
        form.targetWeightUnitInitialCheck = true;
        form.targetWeightUnit = weightUnit;
        if (weightUnit === 'lb') {
          updateForm(weightUnits.lb, 'targetWeightUnit');
        }
      }
    }
    if (form.targetAge) {
      form.useFinalWeight = false;
    }
  }

  return (
    <React.Fragment>
      <Label type="text">
        <FormattedMessage id="common.breed" defaultMessage="Breed" />
      </Label>
      <SelectBird birds={birds} bird={bird} onChange={b => updateForm((b || {}).id, 'birdId')} />
      <Label type="text">
        <FormattedMessage id="flocks.modal.measure-goal" defaultMessage="Measure goal" />
      </Label>
      <ButtonSwitch
        options={[
          { title: <FormattedMessage id="flocks.modal.none" defaultMessage="None" />, value: null },
          { title: <FormattedMessage id="flocks.modal.ideal-growth" defaultMessage="Ideal growth" />, value: false },
          { title: <FormattedMessage id="flocks.modal.final-weight" defaultMessage="Final weight" />, value: true },
        ]}
        selected={form.useFinalWeight}
        onChange={v => updateForm(v, 'useFinalWeight')}
      />
      {
        form.useFinalWeight === true && (
          <React.Fragment>
            <FormLabel required={hasErrors.targetWeight}>
              <FormattedMessage id="flocks.modal.final-weight" defaultMessage="Final weight" />
            </FormLabel>
            <InputGroup>
              <WeightInput
                min={weightUnit === 'g' ? 1 : 0.001}
                type="number"
                value={form.targetWeight}
                weightUnit={form.targetWeightUnit}
                onChange={e => updateFormEvent(e, 'targetWeight')}
                autoFocus
              />
              <UncontrolledDropdown addonType="append">
                <DropdownToggle caret color="primary" outline className="form-control">
                  <FormattedMessage id={`common.${form.targetWeightUnit || 'grams'}`} defaultMessage="grams" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => updateForm(weightUnits.g, 'targetWeightUnit')}>
                    <FormattedMessage id="common.grams" defaultMessage="grams" />
                  </DropdownItem>
                  <DropdownItem onClick={() => updateForm(weightUnits.kg, 'targetWeightUnit')}>
                    <FormattedMessage id="common.kilograms" defaultMessage="kilograms" />
                  </DropdownItem>
                  <DropdownItem onClick={() => updateForm(weightUnits.lb, 'targetWeightUnit')}>
                    <FormattedMessage id="common.pounds" defaultMessage="pounds" />
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </InputGroup>
            {!edit && (
              <Label type="info">
                <FormattedMessage id="flocks.modal.final-weight-info" defaultMessage="Set a target weight" />
              </Label>
            )}
          </React.Fragment>
        )
      }
      {
        form.useFinalWeight === false && (
          <React.Fragment>
            <FormLabel required={hasErrors.targetAge}>
              <FormattedMessage id="flocks.modal.grow-period" defaultMessage="Grow period" />
            </FormLabel>
            <InputGroup>
              <TimeInput
                min={1}
                type="number"
                value={form.targetAge != null ? form.targetAge : form.birdDuration}
                timeUnit={form.targetAgeUnit}
                onChange={e => updateFormEvent(e, 'targetAge')}
                autoFocus
              />
              <UncontrolledDropdown addonType="append">
                <DropdownToggle caret color="primary" outline className="form-control">
                  {
                    form.targetAgeUnit === timeUnits.weeks
                      ? <FormattedMessage id="common.weeks" defaultMessage="weeks" />
                      : <FormattedMessage id="common.days" defaultMessage="days" />
                  }
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => updateFormObject({
                      targetAgeUnit: timeUnits.weeks,
                      targetAge: timeRound(form.targetAge, timeUnits.weeks),
                    })}
                  >
                    <FormattedMessage id="common.weeks" defaultMessage="weeks" />
                  </DropdownItem>
                  <DropdownItem onClick={() => updateForm(timeUnits.days, 'targetAgeUnit')}>
                    <FormattedMessage id="common.days" defaultMessage="days" />
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </InputGroup>
            {!edit && (
              <Label type="info">
                <FormattedMessage id="flocks.modal.grow-period-info" defaultMessage="How long to growth your flock" />
              </Label>
            )}
          </React.Fragment>
        )
      }
    </React.Fragment>
  );
};

Step3.propTypes = {
  modal: PropTypes.shape({
    data: PropTypes.object,
    base: PropTypes.object,
  }).isRequired,
  updateForm: PropTypes.func.isRequired,
  updateFormObject: PropTypes.func.isRequired,
  updateFormEvent: PropTypes.func.isRequired,
  birds: PropTypes.arrayOf(birdType),
  weightUnit: PropTypes.string,
};

Step3.defaultProps = {
  birds: [],
  weightUnit: null,
};

const mapStateToProps = (state) => {
  return {
    birds: state.bird.modal,
    weightUnit: state.auth.weightUnit,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  updateForm: ModalActions.updateForm,
  updateFormObject: ModalActions.updateFormObject,
  updateFormEvent: ModalActions.updateFormEvent,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Step3);
