import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
} from '@veit/veit-web-controls';

import ModalWithLoader from '../ModalWithLoader';
import bem from '../../utils/bem';
import SubmitHandler, { keyCodes } from '../../components/SubmitHandler';
import DateInput from '../../components/DateInput';

import ModalActions, { finishDialogCloseFlock } from '../../store/ModalFlock.actions';

class CloseFlockDialog extends Component {
  state = {
    end: new Date(),
  }

  confirm = () => {
    this.props.finishDialogCloseFlock(this.props.modal.data, this.state.end);
  }

  toggle = () => {
    this.props.cancelDialog();
  }

  render() {
    const bm = bem.modal('close-flock');
    const { data } = this.props.modal;
    return (
      <div className={bm.b()}>
        <ModalWithLoader className={bm.e('modal')} keyboard={false} centered isOpen toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}></ModalHeader>
          <ModalBody>
            <Label type="title">
              <FormattedMessage id="flocks.modal.are-you-sure" defaultMessage="Are you sure?" />
            </Label>
            <Label className="subtitle">
              <FormattedMessage values={{ name: (<b>{data.name}</b>) }} id="flocks.modal.close-flock-dialog" defaultMessage="Do you want to close the flock {name}?" />
            </Label>
            <div style={{ textAlign: 'left' }}>
              <Label type="text">
                <FormattedMessage id="flocks.modal.end-date" defaultMessage="End date" />
              </Label>
              <DateInput
                value={this.state.end}
                dateFormat={this.props.dateFormat}
                onChange={end => this.setState({ end })}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" outline onClick={this.toggle}>
              <FormattedMessage id="flocks.modal.dont-do-it" defaultMessage="Don’t do it" />
            </Button>
            <Button color="danger" onClick={this.confirm}>
              <FormattedMessage id="flocks.modal.close-flock" defaultMessage="Close Flock" />
            </Button>
          </ModalFooter>
          <SubmitHandler action={this.confirm} keyCode={keyCodes.enter} />
          <SubmitHandler action={this.toggle} keyCode={keyCodes.escape} />
        </ModalWithLoader>
      </div>
    );
  }
}

CloseFlockDialog.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  finishDialogCloseFlock: PropTypes.func.isRequired,
  modal: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
  dateFormat: PropTypes.string,
};

CloseFlockDialog.defaultProps = {
  dateFormat: null,
};

const mapStateToProps = (state) => {
  return {
    dateFormat: state.auth.dateFormat,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  updateForm: ModalActions.updateForm,
  finishDialogCloseFlock,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CloseFlockDialog);
