import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {
  Button, Table, Label, StatusIcon,
} from '@veit/veit-web-controls';
import IMErrorMark from '@veit/veit-web-controls/dist/icons/IMErrorMark';


import { postRequestScaleData } from '../../store/Device.actions';
import { openDialogEditDevice } from '../../store/ModalDevice.actions';
import { openDialogExportStats } from '../../store/ModalExportStats.actions';
import { openDialogHistogram } from '../../store/ModalHistogram.actions';
import { openDialogImportData } from '../../store/ModalImport.actions';
import { goTo } from '../../store/Router.actions';

import toastMessage from '../../utils/toastMessage';
import fetchData from './fetchData';
import DeviceSubtitle from './Subtitle';
import bem from '../../utils/bem';
import { deviceType, authType } from '../../model/enums';
import Page from '../../components/Page';
import DateFormat from '../../components/DateFormat';
import SexIcon from '../../components/SexIcon';
import Round from '../../components/Round';
import ArrowNavigation, { getGuid } from '../../components/ArrowNavigation';
import AuthButton, { AuthAddButton } from '../../components/AuthButton';
import HistogramPreview from '../../charts/HistogramPreview';
import WeightValue from '../../components/WeightValue';

class DeviceAutomatic extends Component {
  componentDidMount() {
    if (this.props.id != null) {
      this.props.fetchData(this.props.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps == null || this.props.id !== prevProps.id) {
      this.props.fetchData(this.props.id);
    }
  }

  editDevice = () => {
    this.props.openDialogEditDevice({
      ...this.props.device.item,
    }).then((result) => {
      if (result == null) return;
      if (result.delete) {
        this.props.goTo('/scales');
      } else {
        this.props.fetchData(this.props.id);
      }
    });
  }

  exportData = () => {
    this.props.openDialogExportStats({ device: this.props.device.item });
  }

  importData = () => {
    this.props.openDialogImportData(this.props.device.item);
  }

  requestData = () => {
    this.props.postRequestScaleData(this.props.device.item.id).then((response) => {
      toastMessage.success(response.data.message);
    });
  }

  showHistogram = (stats, stat) => {
    this.props.openDialogHistogram(stat, stats);
  }

  applyFilter = (stats, ascending) => {
    if (stats == null) return [];
    return stats.sort((a, b) => (ascending ? a.dateTime - b.dateTime : b.dateTime - a.dateTime));
  }

  hasHistogram = (histogram) => {
    if (histogram == null || histogram.steps == null) return false;
    return Object.keys(histogram.steps).length > 0;
  }

  canRequestData = () => {
    const { phoneNumber } = this.props.device.item;
    const { gateways } = this.props;
    const result = parsePhoneNumberFromString(phoneNumber);
    if (result == null || !result.isValid() || result.country == null) return false;
    return gateways
      .find(f => f != null && f.toUpperCase() === result.country.toUpperCase()) != null;
  }

  actions(type) {
    const device = this.props.device.item;
    return (
      <React.Fragment>
        {
          type !== deviceType.bat2Cable
            ? (
              <AuthAddButton canUpdate={device} onClick={this.importData}>
                <FormattedMessage id="modals.import.import-data" defaultMessage="Import Data" />
              </AuthAddButton>
            )
            : null
        }
        <AuthButton canUpdate={device} color="primary" outline onClick={this.editDevice}>
          <FormattedMessage id="devices.edit-device" defaultMessage="Edit Device" />
        </AuthButton>
        {/* <Button color="primary" outline onClick={this.exportData}>
          <FormattedMessage id="devices.export" defaultMessage="Export" />
        </Button> */}
        {
          type === deviceType.bat2Gsm && this.canRequestData()
            ? (
              <AuthButton canUpdate={device} color="primary" outline onClick={this.requestData}>
                <FormattedMessage id="devices.request-data" defaultMessage="Request Data" />
              </AuthButton>
            )
            : null
        }
      </React.Fragment>
    );
  }

  render() {
    const bm = bem.view('device');
    const device = this.props.device.item || {};
    const { next, prev } = this.props.device.links || {};
    const data = Array.isArray(this.props.stats)
      ? this.props.stats
      : (this.props.stats || {}).data || [];
    const stats = this.applyFilter(data);
    const showHistogram = deviceType.bat2Cable === device.type
      || deviceType.bat2 === device.type
      || deviceType.bat2Lora === device.type;
    const unit = this.props.weightUnit;
    const format = this.props.dateFormat;
    return (
      <Page
        className={bm.b()}
        title={device.name}
        actions={this.actions(device.type)}
        isFetching={this.props.fetch.isFetching}
        notFound={device.id !== this.props.id}
        header={(
          <div style={{ marginTop: '20px' }}>
            <ArrowNavigation
              text={<FormattedMessage id="common.scale-small" defaultMessage="scale" />}
              urlBase="/scale"
              goTo={this.props.goTo}
              next={getGuid(next)}
              prev={getGuid(prev)}
            />
          </div>
        )}
        emptyPage={{
          isEmpty: data.length === 0,
          icon: IMErrorMark,
          text: deviceType.bat2Cable === device.type || !authType.canUpdate(device)
            ? <FormattedMessage id="devices.no-data" defaultMessage="Device does not have any data." />
            : <FormattedMessage id="devices.no-data-start-with" defaultMessage="Device does not have any data. Start with" />,
          action: <AuthAddButton canUpdate={device} onClick={this.importData}><FormattedMessage id="modals.import.import-data" defaultMessage="Import Data" /></AuthAddButton>,
        }}
        subtitle={(<DeviceSubtitle device={device} />)}
      >
        <Table type="data">
          <thead>
            <tr>
              <th className="text-center">
                <FormattedMessage id="common.day" defaultMessage="Day" />
              </th>
              <th className="text-center">
                <FormattedMessage id="common.date" defaultMessage="Date" />
              </th>

              <th className="text-center">
                <FormattedMessage id="common.growth" defaultMessage="Growth" />
              </th>
              <th className="text-center">
                <FormattedMessage id="common.sex" defaultMessage="Sex" />
              </th>
              <th>
                <FormattedMessage values={{ unit: <FormattedMessage id={`units.${unit}`} defaultMessage="g" /> }} id="common.gain-unit" defaultMessage="Gain({unit})" />
              </th>
              <th>
                <FormattedMessage values={{ unit: <FormattedMessage id={`units.${unit}`} defaultMessage="g" /> }} id="common.weight-unit" defaultMessage="Weight({unit})" />
              </th>
              <th>
                <FormattedMessage id="common.count-pcs" defaultMessage="Count(pcs)" />
              </th>
              <th>
                <FormattedMessage id="common.unifor-percent" defaultMessage="Unifor.(%)" />
              </th>
              <th>
                <FormattedMessage id="common.cv-percent" defaultMessage="CV(%)" />
              </th>
              {showHistogram && (
                <th className="text-center">
                  <FormattedMessage id="common.histogram" defaultMessage="Histogram" />
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {stats.map((d, i) => (
              <tr key={`${d.dateTime || i}_${d.category}`}>
                <td className="text-center">
                  <Label>{d.day}</Label>
                </td>
                <td className="text-center">
                  <Label><DateFormat date={d.dateTime} format={format} /></Label>
                </td>
                <td className="text-center"><StatusIcon hasError={!(d.gain > 0)} /></td>
                <td className="text-center"><SexIcon sex={d.category} /></td>
                <td>
                  <Label>
                    <WeightValue value={d.gain} weightUnit={unit} showUnit={false} />
                  </Label>
                </td>
                <td>
                  <Label>
                    <WeightValue value={d.average} weightUnit={unit} showUnit={false} />
                  </Label>
                </td>
                <td><Label>{d.count}</Label></td>
                <td><Label><Round value={d.uni} decimals={1} /></Label></td>
                <td><Label><Round value={d.cv} decimals={1} /></Label></td>
                {showHistogram && (
                  <td className="histogram-cell">
                    {this.hasHistogram(d.histogram) && (
                      <React.Fragment>
                        <HistogramPreview data={d.histogram} height="40" />
                        <Button
                          onClick={() => this.showHistogram(stats, d)}
                          color="primary"
                          className="btn-narrow"
                        >
                          <FormattedMessage id="common.show-details" defaultMessage="Show details" />
                        </Button>
                      </React.Fragment>
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </Page>
    );
  }
}

DeviceAutomatic.propTypes = {
  id: PropTypes.string.isRequired,
  fetchData: PropTypes.func.isRequired,
  openDialogEditDevice: PropTypes.func.isRequired,
  openDialogExportStats: PropTypes.func.isRequired,
  openDialogHistogram: PropTypes.func.isRequired,
  openDialogImportData: PropTypes.func.isRequired,
  postRequestScaleData: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  fetch: PropTypes.shape({
    isFetching: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }).isRequired,
  device: PropTypes.shape({
    item: PropTypes.shape({
      id: PropTypes.string,
      locationId: PropTypes.string,
      farmId: PropTypes.string,
      phoneNumber: PropTypes.string,
    }),
    links: PropTypes.object,
  }),
  stats: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string,
    }),
    PropTypes.array,
  ]),
  gateways: PropTypes.arrayOf(PropTypes.string).isRequired,
  weightUnit: PropTypes.string,
  dateFormat: PropTypes.string,
};

DeviceAutomatic.defaultProps = {
  device: null,
  stats: null,
  weightUnit: null,
  dateFormat: null,
};

const mapStateToProps = (state, ownProps) => {
  return {
    id: ownProps.match.params.id,
    device: state.device,
    stats: state.stats.item,
    fetch: state.fetch,
    gateways: state.device.gateways,
    weightUnit: state.auth.weightUnit,
    dateFormat: state.auth.dateFormat,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchData,
  openDialogEditDevice,
  openDialogExportStats,
  openDialogHistogram,
  openDialogImportData,
  postRequestScaleData,
  goTo,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DeviceAutomatic);
